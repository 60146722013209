import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import Api from '../../../Network/Api';

const PasswordReset = ({ setSnackbarState, force }) => {
  const navigate = useNavigate();
  const [passwordInput, setPasswordInput] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const { user_id, token } = useParams();
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const needmfasetup = searchParams.get('needmfasetup') || false

  const LOGIN_MFA = !needmfasetup ? 'LOGIN' : 'NEXT'
  const LOGIN_URL = !needmfasetup ? '/login' : `/setup-mfa/${user_id}/${token}/${needmfasetup ? '?needmfasetup=true' : ''}`
  const SUCCESS_BUTTON = token ? LOGIN_MFA : 'DASHBOARD';
  const SUCCESS_LINK = token ? LOGIN_URL : '/dashboard/existing_patients';

  useEffect(() => {
    // Check if the query parameter is correct (e.g., 'correct_value')
    if (token && !isTokenValid(token)) {
      // Navigate to another page if the parameter is incorrect
      setSnackbarState(true, "Token is expired. Use forgot pasword to send another email with a new token.", 'error')
      navigate('/forgotpassword');
    }
  }, []);


  const parseTokenForExpireDate = (token) => {

    const base64_encoded = token.split('.')[1]
    const decoded = atob(base64_encoded);
    // Convert the decoded string to a number
    const timestamp = parseInt(decoded, 10);
    // Create a new Date object from the timestamp (in milliseconds)
    const date = new Date(timestamp * 1000);
    return date
  }

  const isTokenValid = (token) =>{
    return (new Date() <= parseTokenForExpireDate(token))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const { currentPassword, newPassword, confirmNewPassword } = event.target;

    // Password requirements
    //   - min 8 chars
    //   - at least one letter [a-zA-Z]
    //   - at least one number [0-9]
    if (newPassword.value.length < 8
      || newPassword.value.match(/[a-zA-Z]/g) === null
      || newPassword.value.match(/[0-9]/g) === null) {
      setSnackbarState(true, 'Password must be at least 8 characters long, contain at least one letter, and contain at least one number', 'error');
      return;
    }

    if (newPassword.value !== confirmNewPassword.value) {
      setSnackbarState(true, 'New passwords must match', 'error');
      return;
    }

    const current = token ? null :  currentPassword.value;
    if (token){
      Api.resetPasswordToken(token, newPassword.value, user_id).then(() => {
        setPasswordResetSuccess(true);
      })
    } else{
      Api.resetPassword(current, newPassword.value).then(() => {
        setPasswordResetSuccess(true);
      });
    }
  };

  const addSecretTokenOrCurrentPassword = (user_id, token) => {
    if (token){
      return <input value={token} name="token" id="token" type="hidden" />;
    }
    return  (
      <FormControl
        display="flex"
        required
        sx={
          {
            flex: 1,
          }
        }
      >
        <FormHelperText
          id="current-password-helper-text"
          sx={
            {
              color: 'form.main',
              marginLeft: 1,
              fontFamily: 'Arvo',
              visibility:!token,
            }
          }
        >
          Current Password*
        </FormHelperText>
        <OutlinedInput
          id="current-password"
          name="currentPassword"
          type="password"
          value={passwordInput.currentPassword}
          onChange={handlePasswordEdit('currentPassword')}
          aria-describedby="current-password-helper-text"
          sx={
            {
              marginTop: 0.5,
              marginLeft: 1,
              visibility: token ? false : true,
              required:  token ? false : true,
              '& .MuiOutlinedInput-input': {
                padding: '0.5rem',
                fontSize: '0.8rem',
              },
            }
          }
        />
      </FormControl>
    )
  }

  const handlePasswordEdit = (prop) => (event) => {
    setPasswordInput({ ...passwordInput, [prop]: event.target.value });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      flex={1}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        width="290px"
      >
        <Typography
          sx={{
            fontFamily: 'Arvo',
            fontSize: '1.2rem',
            color: 'text.primary',
          }}
        >
          Password Reset
        </Typography>
        {
          force ? (
            <Typography
              sx={{
                fontSize: '0.9rem',
                color: 'text.primary',
              }}
            >
              You must reset your password before you can continue.
            </Typography>
          ) : null
        }
        {
          passwordResetSuccess ? (
            <Box
              display="flex"
              marginTop={2}
              flexDirection="column"
            >
              <Typography
                sx={{
                  fontSize: '1rem',
                  color: 'text.primary',
                  marginBottom: 1,
                }}
              >
                Successfully updated password!
              </Typography>
              <Button
                variant="contained"
                onClick={() => navigate(SUCCESS_LINK)}
                sx={
                  {
                    fontSize: '0.75rem',
                    fontWeight: 600,
                    paddingY: '0.75rem',
                  }
                }
              >
                {SUCCESS_BUTTON}
              </Button>
            </Box>
          ) : (
            <form onSubmit={(event) => handleSubmit(event)}>
              
              <Box
                display="flex"
                marginTop={2}
                marginBottom={2}
                flexDirection="column"
                
              >
                {addSecretTokenOrCurrentPassword(user_id, token)}
                <FormControl
                  display="flex"
                  required
                  sx={
                    {
                      flex: 1,
                    }
                  }
                >
                  <FormHelperText
                    id="new-password-helper-text"
                    sx={
                      {
                        color: 'form.main',
                        marginLeft: 1,
                        fontFamily: 'Arvo',
                      }
                    }
                  >
                    New Password*
                  </FormHelperText>
                  <OutlinedInput
                    id="new-password"
                    name="newPassword"
                    type="password"
                    value={passwordInput.newPassword}
                    onChange={handlePasswordEdit('newPassword')}
                    aria-describedby="new-password-helper-text"
                    sx={
                      {
                        marginTop: 0.5,
                        marginLeft: 1,
                        '& .MuiOutlinedInput-input': {
                          padding: '0.5rem',
                          fontSize: '0.8rem',
                        },
                      }
                    }
                  />
                </FormControl>
                <FormControl
                  display="flex"
                  required
                  sx={
                    {
                      flex: 1,
                    }
                  }
                >
                  <FormHelperText
                    id="confirm-new-password-helper-text"
                    sx={
                      {
                        color: 'form.main',
                        marginLeft: 1,
                        fontFamily: 'Arvo',
                      }
                    }
                  >
                    Confirm New Password*
                  </FormHelperText>
                  <OutlinedInput
                    id="confirm-new-password"
                    name="confirmNewPassword"
                    type="password"
                    value={passwordInput.confirmNewPassword}
                    onChange={handlePasswordEdit('confirmNewPassword')}
                    aria-describedby="confirm-new-password-helper-text"
                    sx={
                      {
                        marginTop: 0.5,
                        marginLeft: 1,
                        '& .MuiOutlinedInput-input': {
                          padding: '0.5rem',
                          fontSize: '0.8rem',
                        },
                      }
                    }
                  />
                </FormControl>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  variant="contained"
                  sx={
                    {
                      fontSize: '0.75rem',
                      fontWeight: 600,
                      paddingY: '0.75rem',
                      paddingX: '1.5rem',
                    }
                  }
                >
                  RESET PASSWORD
                </Button>
              </Box>
            </form>
          )
        }
      </Box>
    </Box>
  );
};

PasswordReset.propTypes = {
  setSnackbarState: PropTypes.func.isRequired,
  force: PropTypes.bool.isRequired,
};

export default PasswordReset;
