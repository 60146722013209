import React from 'react';
import { Navigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Api from '../Network/Api';

const AuthorizedRedirectRoute = ({ children }) => {
  // If user is logged in already, they should not be able
  // to partake in some actions such as logging in again.
  // Navigate to dashboard instead
  if (Api.isAuthorized()) {
    return <Navigate to="/dashboard/existing_patients" replace />;
  }

  return children;
};

AuthorizedRedirectRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default AuthorizedRedirectRoute;
