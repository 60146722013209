// Label.js
const LabelComponent = ({ text }) => {
  return (
    <label
        style={{
            'paddingBottom':'.5em',
            'paddingTop':'1em'
        }}
    >
        {text}
    </label>
  );
};

export default LabelComponent;