import { Subject } from 'rxjs';
import axios from 'axios';

class Api {
  constructor() {
    this.token = sessionStorage.getItem('token');
    this.errors = new Subject();
    this.root = process.env.API_ROOT;
  }

  isAuthorized() {
    return this.token !== null;
  }

  getAuthOptions() {
    return {
      headers: {
        Authorization: `JWT ${this.token}`,
      },
      validateStatus: (status) => status >= 200 && status < 400,
    };
  }

  handleError(error, failure) {
    const { response } = error;

    switch (response.status) {
      case 401:
        this.logout();
        break;
      default:
    }
    if (response.status !== 401 && failure) {
      failure();
      return Promise.reject(error);
    }
    if (error.response && error.response.data) {
      if (error.response.data.error) {
        this.errors.next({ message: error.response.data.error });
      }
      else if (error.response.data.errors) {
        this.errors.next({ message: error.response.data.errors[0] });
      } 
      else if (error.response.data.non_field_errors) {
        this.errors.next({ message: error.response.data.non_field_errors[0] });
      } else if (error.response.data.message) {
        this.errors.next({ message: error.response.data.message });
      } else {
        this.errors.next(error);
      }
    } else {
      this.errors.next(error);
    }

    return Promise.reject(error);
  }

  // AUTH

  login(username, password) {
    return axios.post(`${this.root}/api-token-auth/`, {
      username,
      password,
    }).then(({ data }) => {
      const { token } = data;
      if (token) {
        sessionStorage.setItem('token', token);
        this.token = token;
      }

      return data;
    }).catch((error) => this.handleError(error));
  }

  loginWithMFA(username, password, code) {
    return axios.post(`${this.root}/api-token-auth/`, {
      username,
      password,
      otp_token: code,
    }).then(({ data }) => {
      const { token } = data;
      if (token) {
        sessionStorage.setItem('token', token);
        this.token = token;
      }

      return data;
    }).catch((error) => this.handleError(error));
  }

  logout() {
    sessionStorage.removeItem('token');
    this.token = null;
  }
  forgotPassword(username) {
    return axios.post(`${this.root}/request-reset/`, {
      username,
    }).then(({ data }) => {

      return data;
    }).catch((error) => this.handleError(error));
  }

  resetPassword(oldPassword, newPassword) {
    const data = { oldPassword, newPassword}
    const urlPath = '/password-reset/'
    return axios.post(`${this.root}${urlPath}`, data, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  resetPasswordToken(token, newPassword, user_id) {
    const data = { token, newPassword, user_id }
    const urlPath ='/token-password-reset/' 
    return axios.post(`${this.root}${urlPath}`, data, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  setupMFAToken(selected, phone, token, user_id) {
    const data = { selected, phone, token , user_id}
    const urlPath ='/setup-mfa-token/' 
    return axios.post(`${this.root}${urlPath}`, data, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  setupMFA(selected, phone) {
    const data = { selected, phone}
    const urlPath ='/setup-mfa/' 
    return axios.post(`${this.root}${urlPath}`, data, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  sendOTPCode(username, password) {
    const urlPath ='/request-otp/' 
    return axios.post(`${this.root}${urlPath}`, {username, password}, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }
  

  // PATIENTS
  searchPatients(dob, lastName) {
    let url = `${this.root}/patients/?`;
    if (dob !== undefined && dob != null && dob !== '') {
      url = `${url}dob=${dob}`;
    }
    if (lastName !== undefined && lastName != null && lastName !== '') {
      url = `${url}&last_name=${lastName}`;
    }
    return axios.get(url, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getPatient(url) {
    return axios.get(url, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  createPatient(patient, failure) {
    return axios.post(`${this.root}/patients/`, patient, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error, failure));
  }

  patchPatient(url, update) {
    return axios.patch(url, update, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  // PROTOCOLS

  getProtocol(url) {
    return axios.get(url, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getProtocols(dob, lastName, patientId, minAge, maxAge, riskAssement, diagnosis, fallenSinceLast) {
    let url = `${this.root}/protocols/`;

    const params = new URLSearchParams();

    if (dob) {
      params.append('dob', dob);
    }

    if (lastName) {
      params.append('last_name', lastName);
    }

    if (patientId) {
      params.append('medical_id', patientId);
    }
    if (minAge) {
      params.append('min_age', minAge);
    }
    if (maxAge) {
      params.append('max_age', maxAge);
    }
    if (riskAssement) {
      params.append('risk_assement', riskAssement);
    }

    if (diagnosis && diagnosis.length) {
      params.append('diagnosis', diagnosis);
    }
    if (fallenSinceLast) {
      params.append('fallen_since_last', fallenSinceLast);
    }

    const queryString = params.toString();
    url = `${url}?${queryString}`;

    return axios.get(url, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  createProtocol(protocol) {
    return axios.post(`${this.root}/protocols/`, protocol, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  patchProtocol(url, updates) {
    return axios.patch(url, updates, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getProtocolLiveView(protocolId) {
    // Fetch headset view frames, no need to error on failed frame
    return axios.get(`${this.root}/headset/headset-view/?protocol_id=${protocolId}`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch(() => Promise.resolve([])); // We do not want to throw error here
  }

  // TRIALS

  createTrial(trial) {
    return axios.post(`${this.root}/trials/`, trial, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  patchTrial(trialUrl, trial) {
    return axios.patch(`${trialUrl}`, trial, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getTrialsForProtocol(protocolId) {
    return axios.get(`${this.root}/trials/?protocol=${protocolId}`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  // SIB REPORTS

  getReport(id) {
    return axios.get(`${this.root}/reports/${id}/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  patchReport(id, report) {
    return axios.patch(`${this.root}/reports/${id}/`, report, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  // RESIDENTIAL FACILITY REPORTS

  getRFSafetyNetAssessmentMonthlySummary(currMonth) {
    return axios.get(`${this.root}/reports/?month=${currMonth ? 'current' : 'previous'}&section=safetyNetAssessmentMonthlySummary`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getRFTwelveMonthFallRisk(currMonth) {
    return axios.get(`${this.root}/reports/?month=${currMonth ? 'current' : 'previous'}&section=twelveMonthFallRisk`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getRFProgramImpact(currMonth) {
    return axios.get(`${this.root}/reports/?month=${currMonth ? 'current' : 'previous'}&section=programImpact`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getRFPatientRiskListing(currMonth) {
    return axios.get(`${this.root}/reports/?month=${currMonth ? 'current' : 'previous'}&section=patientRiskListing`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  // REPORT TYPES

  getReportType(url) {
    return axios.get(url, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  // USER

  getUserInfo() {
    return axios.get(`${this.root}/user_info/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  // PROFILE

  getProfile() {
    return axios.get(`${this.root}/profile/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  // USER PROFILE

  getUserProfile() {
    return axios.get(`${this.root}/profile_info/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  // DEVICES

  getDevices() {
    return axios.get(`${this.root}/devices/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  // MISC

  getHeadsetStates() {
    return axios.get(`${this.root}/head_state/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getGenderOptions() {
    return axios.get(`${this.root}/gender/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getDiagnosisOptions() {
    return axios.get(`${this.root}/diagnosis/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getMedicationOptions() {
    return axios.get(`${this.root}/medication/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getEyeTypeOptions() {
    return axios.get(`${this.root}/eye_type/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getGroundTypeOptions() {
    return axios.get(`${this.root}/ground_type/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getStroopTypeOptions() {
    return axios.get(`${this.root}/stroop_type/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getBatteryLevels() {
    return axios.get(`${this.root}/battery-level/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  generateExport(protocol_ids) {
    return axios.post(`${this.root}/protocol-export/`, {protocol_ids}, {
      ...this.getAuthOptions(),
      responseType: 'blob',
      exposedHeaders: ['Content-Disposition']
    })
      .then(({ data }) => {
        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(data);
        // Create a temporary <a> element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        const filename = `export-${year}${month}${day}${hours}${minutes}${seconds}.zip`;
        a.download = filename; // Specify the file name
        a.click();
        return data;
      })
      .catch((error) => this.handleError(error, 'failure'));
  }
}

export default new Api();
