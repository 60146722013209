import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      paper: '#FFF',
      default: '#FFF',
    },
    common: {
      white: '#FFF',
      black: '#000',
    },
    primary: {
      main: '#3261DA',
    },
    secondary: {
      main: '#5090D4',
    },
    error: {
      main: '#C13E37',
    },
    warning: {
      main: '#FEEA02',
    },
    info: {
      main: '#3261DA',
    },
    success: {
      main: '#09C35A',
    },
    divider: '#AEB7B6',
    text: {
      primary: '#4D4D4D',
    },
    unselected: {
      main: '#4D4D4D',
    },
    border: {
      main: '#E6E7E8',
    },
    form: {
      main: '#3292DA',
    },
    hover: {
      main: '#E4EAFB',
    },
    primaryLight: {
      main: '#F2F6FF',
    },
    activeTrial: {
      main: '#00F2FF',
    },
    infoBar: {
      main: '#FAF4DD',
    },
    upright: {
      main: '#00BFFF',
    },
    reportInfo: {
      main: '#808080',
    },
  },
  spacing: 8,
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      fontFamily: 'Montserrat',
    },
    h2: {
      fontFamily: 'Montserrat',
    },
    h3: {
      fontFamily: 'Montserrat',
    },
    h4: {
      fontFamily: 'Montserrat',
    },
    h5: {
      fontFamily: 'Montserrat',
    },
    h6: {
      fontFamily: 'Montserrat',
    },
  },
});

export default theme;
