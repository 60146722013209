import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => (
  <Box
    display="flex"
    flex={1}
    height="100%"
  >
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  </Box>
);

export default Loading;
