import React, {
  Suspense, lazy, useEffect, useState,
} from 'react';
import {
  HashRouter,
  Route,
  Routes,
} from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import { LocalizationProvider } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Api from './Network/Api';
import theme from './Theme/theme';
import AuthorizedRedirectRoute from './Components/AuthorizedRedirectRoute';
import ErrorBoundary from './Components/ErrorBoundary';
import Loading from './Components/Loading';
import PasswordReset from './Pages/Dashboard/PasswordReset/PasswordReset';
import ProtectedRoute from './Components/ProtectedRoute';
import MFASetup from './Pages/Dashboard/PasswordReset/SetupMFA';

const Login = lazy(() => import('./Pages/Authentication/Login/Login'));
const ForgotPassword = lazy(() => import('./Pages/Authentication/Login/ForgotPassword'));
const Dashboard = lazy(() => import('./Pages/Dashboard/Dashboard'));
const PageNotFound = lazy(() => import('./Pages/PageNotFound/PageNotFound'));

const App = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const handleSetSnackbarState = (open, message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => (
    Api.errors.subscribe(
      (error, a) => {
        setSnackbarMessage(error.message);
        setSnackbarOpen(true);
      },
    ).unsubscribe
  ), []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Suspense fallback={<Loading />}>
            <HashRouter>
              <ErrorBoundary>
                <Routes>
                  <Route path="/">
                    <Route
                      index
                      element={(
                        <AuthorizedRedirectRoute>
                          <Login />
                        </AuthorizedRedirectRoute>
                      )}
                    />
                    <Route
                      path="/login"
                      element={(
                        <AuthorizedRedirectRoute>
                          <Login />
                        </AuthorizedRedirectRoute>
                      )}
                    />
                    <Route
                      path="/forgotpassword"
                      element={(
                        <AuthorizedRedirectRoute>
                          <ForgotPassword />
                        </AuthorizedRedirectRoute>
                      )}
                    />
                    <Route
                      path="/password_reset/:user_id/:token/"
                      element={(
                        <AuthorizedRedirectRoute>
                          <PasswordReset setSnackbarState={handleSetSnackbarState} force={true} />
                        </AuthorizedRedirectRoute>
                      )}
                    />
                    <Route
                      path="/setup-mfa/:user_id/:token/"
                      element={(
                        <AuthorizedRedirectRoute>
                          <MFASetup setSnackbarState={handleSetSnackbarState} force={true} />
                        </AuthorizedRedirectRoute>
                      )}
                    />
                    <Route
                      path="/dashboard/*"
                      element={(
                        <ProtectedRoute>
                          <Dashboard />
                        </ProtectedRoute>
                      )}
                    />
                    <Route path="*" element={<PageNotFound />} />
                  </Route>
                </Routes>
              </ErrorBoundary>
            </HashRouter>
            <Snackbar
              autoHideDuration={5000}
              open={snackbarOpen}
              onClose={() => setSnackbarOpen(false)}
            >
              <Alert
                elevation={6}
                severity="error"
                variant="filled"
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Suspense>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
