import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import LabelComponent from '../../../Components/Label'
import Api from '../../../Network/Api';
import logo from '../../../Assets/logo-alternate.png';

const MFASetup = ({ setSnackbarState, force, profile }) => {
  const navigate = useNavigate();
  const [mfaInput, setMFAInput] = useState({
    selected: '',
    email: '',
    phone: '',
  });

  const NO_EMAIL = 'NO_EMAIL';

  const [selectedOption, setSelectedOption] = useState('');
  const { user_id, token } = useParams();
  const [MFASetupSuccess , setMFASetupSuccess ] = useState(false);

  const SUCCESS_BUTTON = token ? 'LOGIN' : 'DASHBOARD';
  const SUCCESS_LINK = token ? '/login' : '/dashboard/existing_patients';

  if(profile && !mfaInput.email){
    setMFAInput({...mfaInput, email:profile.email, phone:profile.phoneNumber, selected:profile.mfaDevice})
    setSelectedOption(profile.mfaDevice)
  }

  useEffect(() => {

    // Check if the query parameter is correct (e.g., 'correct_value')
    if (token && !isTokenValid(token)) {
      // Navigate to another page if the parameter is incorrect
      setSnackbarState(true, "Token is expired. Use forgot pasword to send another email with a new token.", 'error')
      navigate('/forgotpassword');
    }

    if(token) {
        parseEmailForMFA(token);
    }
  }, []);


  const parseTokenForExpireDate = (token) => {

    const base64_encoded = token.split('.')[1]
    const decoded = atob(base64_encoded);
    // Convert the decoded string to a number
    const timestamp = parseInt(decoded, 10);
    // Create a new Date object from the timestamp (in milliseconds)
    const date = new Date(timestamp * 1000);
    return date
  }

  const parseEmailForMFA = (token) => {
    const base64_encoded = token.split('.')[2]
    const decoded = atob(base64_encoded);
    setMFAInput({ ...mfaInput, email: decoded })
  }

  const isTokenValid = (token) =>{
    return (new Date() <= parseTokenForExpireDate(token))
  }

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[+]?[0-9]{8,}$/; // Adjust regex based on your requirements
    return phoneRegex.test(phoneNumber) && (phoneNumber.length == 10 || phoneNumber.length == 11);
  };
  

  const handleSubmit = (event) => {
    event.preventDefault();
    const { selected, phone } = event.target;

    if(selected){
        setSnackbarState(true, 'You must choose a MFA device.', 'error');
        return;
    }
    if (selected === 'phone'){
        const isValid = validatePhoneNumber(phone);
        if (!isValid){
            setSnackbarState(true, 'Phone number is invalid. Please fix or choose a differnt MFA device.', 'error');
            return;
        }
    }

    // const current = token ? null :  currentPassword.value;
    if (token){
      Api.setupMFAToken(mfaInput.selected, mfaInput.phone, token,  user_id).then(() => {
        setMFASetupSuccess(true);
      })
    } else{
      Api.setupMFA(mfaInput.selected, mfaInput.phone).then(() => {
        setMFASetupSuccess(true);
      });
    }
  };

  const handleMFAOptionChange = (option) => {
    const value = option.target ? option.target.value : option 
    setSelectedOption(value);
    mfaInput.selected = value;
  };

  const cleanPhoneNumber = (phoneNumber) => {
    // Remove all non-numeric characters
    return phoneNumber.replace(/\D/g, '');
  };

  const formValid =  () => {
    if(!mfaInput.selected) return false;
    if(mfaInput.selected === 'phone' && !validatePhoneNumber(mfaInput.phone || '')) return false;
    if(mfaInput.selected === 'email' && !mfaInput.email) return false;
    return true;
  }

  const handleInputClean = (event)  => {
    const inputNumber = event.target.value;
    const cleanedNumber = cleanPhoneNumber(inputNumber);
    setMFAInput({ ...mfaInput, phone: cleanedNumber })
  }

  const handleMFAChange = (prop) => (event) => {
    setMFAInput({ ...mfaInput, [prop]: event.target.value });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      flex={1}
      alignItems="center"
      justifyContent="center"
    >
      <Box
            component="img"
            sx={{
            width: '20%',
            minWidth: '224px',
            }}
            alt="logo"
            src={logo}
      />
      {
          force && !MFASetupSuccess ? (
            <Box
              sx={
                  {
                    display:"flex",
                    justifyContent:"center",
                    paddingTop:'1rem'
                  }
                }
            >
              <Typography
                sx={{
                  fontSize: '.9rem',
                  color: 'text.primary',
                  width: '21rem',
                }}
              >
                You must setup MFA to continue. MFA will be required to log in with the first time and every 30 days.
                You can choose to use your email, phone, or an authenticator app. If you choose an authenticator app
                see your welcome email to get the instructions to set that up.
              </Typography>
            </Box>
          ) : <></>
        }
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          paddingLeft: "9rem",
          paddingTop: "2rem",
        }}
      >
        
        <Typography
          sx={{
            fontFamily: 'Arvo',
            fontSize: '1.2rem',
            color: 'text.primary',
          }}
        >
          Multifactor Authentication Setup
        </Typography>
        
        {
          MFASetupSuccess ? (
            <Box
              display="flex"
              marginTop={2}
              flexDirection="column"
            >
              <Typography
                sx={{
                  fontSize: '1rem',
                  color: 'text.primary',
                  marginBottom: 1,
                }}
              >
                Successfully updated MFA Settings!
              </Typography>
              <Box
                sx={
                    {
                      display:"flex",
                      justifyContent:"center"
                    }
                  }
              >
                <Button
                  variant="contained"
                  onClick={() => navigate(SUCCESS_LINK)}
                  sx={
                    {
                      fontSize: '0.75rem',
                      fontWeight: 600,
                      paddingY: '0.75rem',
                    }
                  }
                >
                  {SUCCESS_BUTTON}
                </Button>
              </Box>
            </Box>
          ) : (
            <form onSubmit={(event) => handleSubmit(event)}>
              
              <Box
                display="flex"
                marginTop={2}
                marginBottom={2}
                flexDirection="column"
                maxWidth="75%"
                
              >
                <LabelComponent 
                    text="I already setup MFA using Google Authenticator from my welcome email."
                    style={{ wordWrap: 'break-word' }}
                />
                <Button
                    key='authenticator'
                    variant={mfaInput.selected === 'authenticator' ? 'contained' : 'outlined'}
                    onClick={() => handleMFAOptionChange('authenticator')}
                    sx={{
                        height: '42px',
                        width: '342px',
                        fontSize: '0.75rem',
                        fontWeight: 600,
                        paddingX: '1rem',
                        marginTop: 1,
                        '& .MuiButton-startIcon': {
                        position: 'absolute',
                        top: '11px',
                        left: '20px',
                        height: '20px',
                        width: '20px',
                        },
                    }}
                    startIcon={mfaInput.selected === 'authenticator'
                        ? <CheckBoxIcon />
                        : <CheckBoxOutlineBlankIcon />}
                    >
                    Authenticator
                </Button>

                 
                <LabelComponent 
                    text={'Use Email address ' + (profile ? profile.email : (mfaInput.email !== NO_EMAIL ? mfaInput.email : ''))}
                />
                
                <Button
                    key='email'
                    disabled={mfaInput.email === NO_EMAIL || (!token && !profile)}
                    variant={mfaInput.selected === 'email' ? 'contained' : 'outlined'}
                    onClick={() => handleMFAOptionChange('email')}
                    sx={{
                        height: '42px',
                        width: '342px',
                        fontSize: '0.75rem',
                        fontWeight: 600,
                        paddingX: '1rem',
                        marginTop: 1,
                        '& .MuiButton-startIcon': {
                        position: 'absolute',
                        top: '11px',
                        left: '20px',
                        height: '20px',
                        width: '20px',
                        },
                    }}
                    startIcon={mfaInput.selected === 'email'
                        ? <CheckBoxIcon />
                        : <CheckBoxOutlineBlankIcon />}
                    >
                    Email
                </Button>
                <LabelComponent text='Use Phone' />
                 <Button
                    key='phone'
                    variant={mfaInput.selected === 'phone' ? 'contained' : 'outlined'}
                    onClick={() => handleMFAOptionChange('phone')}
                    sx={{
                        height: '42px',
                        width: '342px',
                        fontSize: '0.75rem',
                        fontWeight: 600,
                        paddingX: '1rem',
                        marginTop: 1,
                        '& .MuiButton-startIcon': {
                        position: 'absolute',
                        top: '11px',
                        left: '20px',
                        height: '20px',
                        width: '20px',
                        },
                    }}
                    startIcon={mfaInput.selected === 'phone'
                        ? <CheckBoxIcon />
                        : <CheckBoxOutlineBlankIcon />}
                    >
                    Phone
                </Button>
                { selectedOption === 'phone' ? 
                
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                
                  <FormControl
                      display="flex"
                      required
                      sx={
                          {
                          flex: 1,
                          }
                      }
                      >
                      <FormHelperText
                          id="confirm-new-password-helper-text"
                          sx={{
                            color: 'form.main',
                            marginLeft: 0,
                            fontFamily: 'Arvo',
                          }}
                      >
                          Phone Number*
                      </FormHelperText>
                      <OutlinedInput
                          id="phone"
                          name="phone"
                          type="tel"
                          required={selectedOption === 'phone'}
                          value={mfaInput.phone}
                          onChange={handleInputClean}
                          aria-describedby="confirm-new-password-helper-text"
                          sx={{
                            marginTop: 0.5,
                            width:'21.5rem',
                            '& .MuiOutlinedInput-input': {
                            padding: '0.5rem',
                            fontSize: '0.8rem',
                            },
                          }}
                      />
                      </FormControl>
                  </Box> : null 
                }
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  disabled={!formValid()}
                  type="submit"
                  variant="contained"
                  sx={
                    {
                      fontSize: '0.75rem',
                      fontWeight: 600,
                      paddingY: '0.75rem',
                      paddingX: '1.5rem',
                    }
                  }
                >
                  Set MFA Settings
                </Button>
              </Box>
            </form>
          )
        }
      </Box>
    </Box>
  );
};

MFASetup.propTypes = {
  setSnackbarState: PropTypes.func.isRequired,
  force: PropTypes.bool.isRequired,
  profile: PropTypes.object,
};

export default MFASetup;
