import React from 'react';
import { Navigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Api from '../Network/Api';

const ProtectedRoute = ({ children }) => {
  if (!Api.isAuthorized()) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default ProtectedRoute;
